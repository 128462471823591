



































































import { Component, Prop } from 'vue-property-decorator';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Exhibitor from '@/models/graphql/Exhibitor';
import ButtonComponent from '@/components/ButtonComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import EntityType from '@/utils/enums/EntityType';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { Data } from '@/utils/types/WidgetData';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import Size from '@/utils/enums/Size';
import { deepSearch } from '@/utils/ObjectHelpers';

@Component({
  components: {
    PillWidget,
    AvatarSoloWidget,
    ButtonComponent,
  },
})
export default class FeaturedCompanyWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({
    required: false,
    default: null,
  })
  private readonly company!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly route!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private data!: Data[];

  private Size = Size;

  private get exhibitor(): Exhibitor | null {
    if (this.data && this.data.length > 0) {
      return Exhibitor.hydrate(this.data[0]);
    }
    return null;
  }

  private get logoSrc(): string | null {
    if (this.exhibitor && this.exhibitor.logoFileResource) {
      return FileResourceHelper.getFullPath(this.exhibitor.logoFileResource, 'w256');
    }
    if (this.community.defaultExhibitorLogoFileResource) {
      return FileResourceHelper.getFullPath(this.community.defaultExhibitorLogoFileResource, 'w256');
    }
    return null;
  }

  private get bannerSrc(): string | null {
    if (this.exhibitor && this.exhibitor.bannerFileResource) {
      return FileResourceHelper.getFullPath(this.exhibitor.bannerFileResource, 'w720');
    }
    if (this.community.defaultExhibitorBannerFileResource) {
      return FileResourceHelper.getFullPath(this.community.defaultExhibitorBannerFileResource, 'w720');
    }
    return null;
  }

  created(): void {
    if (this.payload
        && (('company' in this.payload && this.payload.company)
            || ('filter' in this.payload && this.payload.filter))) {
      let filter = {};
      if ('company' in this.payload && this.payload.company) {
        filter = {
          id: this.payload.company,
          schemaCode: '%community%',
        };
      }
      if ('filter' in this.payload && this.payload.filter) {
        const tempFilter = JSON.parse(this.payload.filter as string);
        if ('filter' in tempFilter && tempFilter.filter) {
          filter = tempFilter.filter;
        } else {
          filter = tempFilter;
        }
      }
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'exhibitor',
        gqlDefinition: buildQueryDefinition({
          cacheable: !this.authUser
              || (!deepSearch(filter, this.authUser.uid)
                  && !deepSearch(filter, '%authUser%')),
          filter: {
            type: GqlEntityFilterType.EXHIBITOR_FILTER,
            value: filter,
          },
          orderBy: {
            type: GqlEntityOrderingType.EXHIBITOR_ORDERING,
            value: ['_random_asc'],
          },
          first: 1,
        }),
        fragmentName: 'exhibitorBaseFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
      );
    }
  }

  private onClick(): void {
    if (this.route && this.exhibitor) {
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        EntityType.EXHIBITOR,
        StatLoggerActions.CLICK,
        '',
        -1,
        this.exhibitor.uid,
        StatLoggerCategories.FEATURED_EXHIBITOR,
        this.$i18n.locale,
      );
      let r = this.route;
      const matches = this.route.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          if (this.$props[prop]) {
            r = r.replaceAll(m, this.$props[prop]);
          }
          if ((this.exhibitor as unknown as Record<string, string>)[prop]) {
            r = r.replaceAll(m, (this.exhibitor as unknown as Record<string, string>)[prop]);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
      }
      if (r[r.length - 1] === '/') {
        this.$router.push(`${r}${this.exhibitor.uid}`);
      } else {
        this.$router.push(`${r}/${this.exhibitor.uid}`);
      }
    }
  }
}
